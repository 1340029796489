import React, { useState, useEffect, useContext } from 'react';
import {
  generatePath,
  Switch,
  Route,
  useHistory,
  useLocation,
  Redirect,
  matchPath,
} from 'react-router-dom';
import { STORAGE_KEYS, USER_STATUS } from 'Enums';
import useConfigSettings from "Hooks/useConfigSettings";
import Header from 'Components/shared/Header';
import Breadcrumbs from 'Components/shared/Header/Breadcrumbs';
import { updateHeaderNavigation } from 'Helpers/updateHeaderNavigation';
import RedeemMethodSelection from './RedeemMethodSelection';
import RedeemDetails from './RedeemDetails';
import RedemptionSummary from './RedeemSummary';
import RedeemQr from './RedeemQr';
import RedeemFinish from './RedeemFinish';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { ROUTES } from 'Router/Routes';
import RedeemError from "./RedeemError";

const headerNavigationSteps = [
  {
    text: i18nextKeys.redeemHeaderNavigationSelectMethod,
    active: true,
  },
  {
    text: i18nextKeys.redeemHeaderNavigationDetails,
    active: false,
  },
  {
    text: i18nextKeys.redeemHeaderNavigationSummary,
    active: false,
  },
  {
    text: i18nextKeys.redeemHeaderNavigationFinish,
    active: false,
  },
];

const Redeem = () => {
  const history = useHistory();
  const { isAdmin, status } = useContext(AuthContext);
  const {
    config: { kycTierFunctionalRestrictions },
  } = useContext(ConfigContext);
  const { isUserTierSufficient } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.redemption
  );
  const { isRestrictedMode } = useConfigSettings.query({
    refetchOnMount: false,
    select: data => data.RestrictedMode
  });
  const location = useLocation();
  const [headerNavigation, setHeaderNavigation] = useState([]);

  useEffect(() => {
    if (!isUserTierSufficient) {
      history.replace(ROUTES.base);
    }
  }, []);

  useEffect(() => {
    let headerNum = 0;
    if (matchPath(location.pathname, { path: ROUTES.redemption, exact: true })) {
      headerNum = 0;
    } else if (
      matchPath(location.pathname, {
        path: ROUTES.redemptionForm,
        exact: true,
      })
    ) {
      headerNum = 1;
    } else if (
      matchPath(location.pathname, ROUTES.redemptionSummary)
    ) {
      headerNum = 2;
    } else if (
      matchPath(location.pathname, ROUTES.redemptionQR) ||
      matchPath(location.pathname, ROUTES.redemptionError) ||
      matchPath(location.pathname, ROUTES.redemptionFinish)
    ) {
      headerNum = 3;
    }

    const updatedNavigationSteps = updateHeaderNavigation(
      headerNavigationSteps.slice(isRestrictedMode && !isAdmin ? 1 : 0),
      isRestrictedMode && !isAdmin ? headerNum - 1 : headerNum
    );

    setHeaderNavigation(updatedNavigationSteps);
  }, [location]);

  const storedRedemption = sessionStorage.getItem(STORAGE_KEYS.allowedRedemption);
  const restrictedModeRedirectPath = storedRedemption
    ? generatePath(
        ROUTES.redemptionForm,
        { id: storedRedemption }
      )
    : ROUTES.welcome

  return (
    <div
      className="main-content-wrapper h-full flex flex-col"
    >
      <Header
        text={i18nextTranslate(i18nextKeys.headerRedemption)}
        children={<Breadcrumbs navigationSteps={headerNavigation} />}
      />

      <Switch>
        {(!isRestrictedMode || isAdmin) && (
          <Route exact path={ROUTES.redemption}>
            <RedeemMethodSelection />
          </Route>
        )}
        {status === USER_STATUS.Approved && (
          <Switch>
            <Route exact path={ROUTES.redemptionForm}>
              <RedeemDetails />
            </Route>
            <Route exact path={ROUTES.redemptionSummary}>
              <RedemptionSummary />
            </Route>
            <Route exact path={ROUTES.redemptionQR}>
              <RedeemQr />
            </Route>
            <Route exact path={ROUTES.redemptionFinish}>
              <RedeemFinish />
            </Route>
            <Route path={ROUTES.redemptionError}>
              <RedeemError />
            </Route>
          </Switch>
        )}
        <Route>
          <Redirect
            to={
              isRestrictedMode && !isAdmin
                ? restrictedModeRedirectPath
                : ROUTES.redemption
            }
          />
        </Route>
      </Switch>
    </div>
  );
};

export default Redeem;
