import React, { useCallback, useContext, useEffect, useState, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Amplitude } from 'react-amplitude-hooks';
import Big from 'big.js';
import Header from 'Components/shared/Header/Header';
import Fade from 'Components/shared/Fade';
import { EmptyCartIcon } from "Components/shared/symbols";
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import useHandleError from 'Utils/handleError';
import { IconCircleWrapper } from 'Helpers/icons';
import { CartContext } from 'States/cart/cartState';
import { UiContext } from 'States/ui/uiState';
import api from 'Api';
import { getMaxPurchasableUnits, sortCartItems } from 'Helpers/assets';
import { objectArrayToObject } from 'Utils/utils';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import { i18nextKeys } from 'Lang/i18nextKeys';
import TableBody from './TableBody';
import TableHeader from './TableHeader';
import FeesFooter from './Fees';
import OrderFooter from './FooterWithFees'
import CartFooter from './Footer';
import useConfigSettings from 'Hooks/useConfigSettings';
import usePurchaseMethods from 'Hooks/usePurchaseMethods';
import { PRICE_SOURCE } from 'Enums';

const Cart = ({
  showHeader = true,
  loading = false,
  proceedButtonAction = () => {},
  inCheckoutProcess = false
}) => {
  const history = useHistory();
  const location = useLocation();
  const handleError = useHandleError();
  const [loadingAssetInfo, setLoadingAssetInfo] = useState(true);
  const [quantitiesValidity, setQuantitiesValidity] = useState({});
  const [validationFinished, setValidationFinished] = useState(false);
  const [loadingFees, setLoadingFees] = useState(inCheckoutProcess);
  const [fees, setFees] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const isPollingEnabled = useRef(false);
  const {
    isLoading: loadingSettings,
    data: {
      currencyCode,
      defaultLanguageCode
    }
  } = useConfigSettings.query({
    select: useCallback(
      ({ Currency, Languages }) => {
        const defaultLanguage = Languages.find(({ IsDefault }) => IsDefault);
        return {
          currencyCode: Currency.Code,
          defaultLanguage: defaultLanguage.Code
        }
      }, []
    )
  });
  const {
    loadingCart,
    pendingCartOperation,
    pendingQuantityUpdate,
    loadCart,
    cartItems,
    removeFromCart,
    emptyCart,
    setFullAssetInfo,
    fullAssetInfo,
  } = useContext(CartContext);
  const {
    breakpoints: { sm, xxl },
  } = useContext(UiContext);

  const {
    data: assetsConfig
  } = usePurchaseMethods.purchaseMethodsQuery({
    enablePolling: isPollingEnabled.current,
    select: useCallback(({ value }) => objectArrayToObject(value, "Id"), [])
  });

  const {
    collectionMethodType,
    invoiceAddress,
    shippingAddress
  } = location.state || {};

  const address = shippingAddress || invoiceAddress;

  useEffect(() => {
    loadCart();
  }, [history]);

  useEffect(() => {
    if (assetsConfig && !loadingCart) {
      if (assetsConfig.length === 0) {
        emptyCart();
      } else {
        validateCartItemsAgainstBlobAssets().then(() => {
          setValidationFinished(true);
        });
      }
    }
  }, [assetsConfig, loadingCart]);

  useEffect(() => {
    if (validationFinished && loadingAssetInfo) {
      getAdditionalAssetInformation();
    }
  }, [validationFinished, cartItems, assetsConfig, loadingAssetInfo]);

  const handleGoBack = () => {
    history.goBack();
  }

  const validateCartItemsAgainstBlobAssets = () => {
    const removeItemPromises = [];
    Object.entries(cartItems).forEach(([uniqueAssetId]) => {
      if (!assetsConfig[uniqueAssetId]) {
        removeItemPromises.push(removeFromCart(uniqueAssetId));
      }
    });
    return Promise.all(removeItemPromises);
  };

  const getAdditionalAssetInformation = () => {
    const cartItemsPromises = [];
    Object.entries(cartItems).forEach(([uniqueAssetId]) => {
      const itemPromise = [];
      itemPromise.push(api.Assets.getAsset(uniqueAssetId));
      itemPromise.push(
        api.Wallets.getBalance(uniqueAssetId, assetsConfig[uniqueAssetId].IssuerAddress),
      );
      cartItemsPromises.push(itemPromise);
    });

    getAdditionalAssetInformationHelper(cartItemsPromises, handleError)
      .then((additionalAssetInfos) => {
        const completeAssetInfos = {};
        additionalAssetInfos.forEach((extraAssetInfo) => {
          const assetInfo = {
            ...assetsConfig[extraAssetInfo.Id],
            ...extraAssetInfo,
            maxPurchasableUnits: getMaxPurchasableUnits(
              extraAssetInfo.availableAmount,
              assetsConfig[extraAssetInfo.Id].MaxAmount,
              true,
            ),
          };
          completeAssetInfos[extraAssetInfo.Id] = assetInfo;
          const priceSource = assetsConfig[extraAssetInfo.Id].PriceSource;
          if (!isPollingEnabled.current && priceSource !== PRICE_SOURCE.none) {
            isPollingEnabled.current = true;
          }
        });
        setFullAssetInfo(completeAssetInfos);
        setLoadingAssetInfo(false);
      })
      .catch((error) => {
        const details = error?.response?.data?.error?.message || error.message;
        const message = i18nextTranslateDynamically(
          i18nextKeys.errorCartAssetsLoadingErrorWithMessage,
          { message: details },
        );
        handleError({ error, message });
      });
  };

  const { availableAssets, unavailableAssets, assetsExceedingMaxAmount } = useMemo(
    () => sortCartItems(cartItems, fullAssetInfo),
    [cartItems, fullAssetInfo]
  );

  const totalNetAmount = useMemo(() => {
    if (!assetsConfig || !availableAssets.length) {
      return 0;
    }
    const totalNetAmount = availableAssets.reduce(
      (sum, Id) => {
        const result = Big(sum).plus(
          Big(cartItems[Id].UnitAmount || 0)
            .times(assetsConfig[Id]?.Price || 0)
            .round(2, Big.roundUp)
        ).toNumber();
        return result;
      },
      0
    );
    return totalNetAmount;
  }, [availableAssets, assetsConfig, cartItems]);

  useEffect(() => {
    const calculateFees = async () => {
      try {
        const { Fees, Amount, TotalFeeAmount } = await api.PurchaseOrder.getFees({
          country: address.Country,
          collectionMethod: collectionMethodType
        });
        setFees([
          ...Fees,
          {
            Type: "total",
            TotalAmount: TotalFeeAmount
          }
        ]);
        setTotalAmount(Amount);
       } catch (error) {
        handleError({ error });
       } finally {
        setLoadingFees(false);
       }
    };
    if (inCheckoutProcess) {
      calculateFees();
    }
  }, [totalNetAmount]);

  const checkoutDisabled = !availableAssets.length
    || !totalNetAmount
    || pendingCartOperation
    || pendingQuantityUpdate
    || assetsExceedingMaxAmount.length
    || Object.values(quantitiesValidity).includes(false);

  const isLoading =
    // loadingAssets ||
    loadingAssetInfo || loading || loadingCart || loadingSettings || loadingFees;

  return (
    <div data-qa="cart" className="select-none">
      <Fade show>
        {showHeader ? (
          <Header
            text={i18nextTranslate(i18nextKeys.cartHeader)}
            onArrowBackClick={handleGoBack}
            arrowBack={sm}
          />
        ) : null}
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingSpinner />
          </div>
        ) : !Object.keys(cartItems).length ? (
          <div className="flex justify-center items-center">
            <div
              className="rounded-full flex justify-center items-center"
              style={{
                width: xxl ? "144px" : "120px",
                height: xxl ? "144px" : "120px",
                marginTop: xxl ? "248px" : sm ? "355px" : "232px",
              }}
            >
              <IconCircleWrapper
                icon={<EmptyCartIcon size={xxl ? 72 : 60} />}
                size={xxl ? "144px" : "120px"}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-24 md:gap-40 color-8 default-table -mx-16 md:mx-0">
              <table data-qa="cart-table-contentWrapper" className={'w-full'}>
                <TableHeader />
                <Amplitude
                  eventProperties={{
                    step: inCheckoutProcess ? "your order" : "cart"
                  }}
                >
                  <TableBody
                    availableAssets={availableAssets}
                    unavailableAssets={unavailableAssets}
                    assetsExceedingMaxAmount={assetsExceedingMaxAmount}
                    currency={currencyCode}
                    defaultLanguage={defaultLanguageCode}
                    setQuantitiesValidity={setQuantitiesValidity}
                    inCheckoutProcess={inCheckoutProcess}
                  />
                </Amplitude>
                {inCheckoutProcess && (
                  <FeesFooter
                    country={address.Country}
                    collectionMethod={collectionMethodType}
                    currency={currencyCode}
                    fees={fees}
                  />
                )}
              </table>
              {inCheckoutProcess ? (
                <OrderFooter
                  totalAmount={totalAmount}
                  currency={currencyCode}
                  pendingCartOperation={pendingCartOperation}
                  proceedButtonAction={proceedButtonAction}
                  checkoutDisabled={checkoutDisabled}
                />
              ) : (
                <CartFooter
                  totalAmount={totalNetAmount}
                  currency={currencyCode}
                  unavailableAssets={unavailableAssets}
                  pendingCartOperation={pendingCartOperation}
                  checkoutDisabled={checkoutDisabled}
                />
              )}
          </div>
        )}
      </Fade>
    </div>
  );
};

export default Cart;

export const getAdditionalAssetInformationHelper = (promises, handleError) => Promise.all(
  promises.map((itemPromise) => Promise.all(itemPromise)
    .then(([assetData, { Amount }]) => {
      const {
        Id,
        Name,
        Description,
        UnitOfMeasureCode,
        CurrencyCode,
        SparkFactor,
        DecimalPrecision,
        Translations
      } = assetData;
      const availableAmount = Number(Amount) / Number(SparkFactor);
      return {
        Id,
        Name,
        Description,
        UnitOfMeasureCode,
        CurrencyCode,
        DecimalPrecision,
        SparkFactor,
        Translations,
        availableAmount
      };
    })
    .catch((error) => {
      const message = i18nextTranslateDynamically(
        i18nextKeys.errorCartAssetsLoadingErrorWithMessage,
        { message: error.message }
      );
      // eslint-disable-next-line no-restricted-globals
      handleError({ error, message, finalNote: false });
    }))
);
