import React, { useContext } from 'react';
import { ConfigContext } from 'States/config/configState';
import { UiContext } from 'States/ui/uiState';
import BurgerMenu from 'Components/shared/BurgerMenu';
import { LoginLogout } from 'Components/shared/buttons';

const Welcome = () => {
  const { config } = useContext(ConfigContext);
  const {
    breakpoints: { lg, md }
  } = useContext(UiContext);

  return (
    <>
      <div className="flex justify-between">
        {!lg ? (
          <div data-qa="welcome-menu">
            <BurgerMenu
              md={md}
              transparent
            />
          </div>
        ) : null}
        <LoginLogout transparent />
      </div>
      {/* fonts are larger than letters so line-height needs adaptation via 'leading-none' */}
      <h1
        style={{
          /* to more or less align with content of Sidebar.js */
          paddingTop: 'calc(7.75rem + 20px)',
        }}
        className="uppercase text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold color-3 leading-none text-right"
      >
        {config?.landingPage?.primaryHeaderText}
      </h1>
      <h1 className="uppercase text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold color-8 leading-none text-right">
        {config?.landingPage?.secondaryHeaderText}
      </h1>
      <h2 className="color-8 text-2xl mt-48 text-right">
        {config?.landingPage?.subHeaderText}
      </h2>
    </>
  );
};

export default Welcome;
