import React, { useContext } from "react";
import BurgerMenu from "Components/shared/BurgerMenu";
import { UiContext } from "States/ui/uiState";
import { DIRECTION } from 'Helpers/icons';
import { Arrow } from "../symbols";

const Header = ({
  text,
  children = null,
  wrapChildren = true,
  headerButton = null,
  classes = "",
  arrowBack = false,
  onArrowBackClick
}) => {
  const {
    breakpoints: { md, lg, xxl }
  } = useContext(UiContext);

  return (
    <div className={`${classes} flex flex-col items-stretch border-b border-color-5 mb-24`}>
      <div className="flex gap-16 items-center mb-16 flex-no-wrap">
        {!lg && (
          <BurgerMenu
            md={md}
            transparent
          />
        )}
        <div className="flex flex-grow justify-between items-center color-6">
          {arrowBack && (
            <span
              className="cursor-pointer"
              onClick={onArrowBackClick}
            >
              <Arrow
                size={xxl ? "40" : "27"}
                direction={DIRECTION.left}
              />
            </span>
          )}
          <div className="flex gap-16 xl:gap-24 mr-16">
            {!!(md || !wrapChildren) && children}
            {headerButton}
          </div>
          <h1
            className="color-6 text-2xl md:text-2xxl xxl:text-3xxl text-right font-extrabold uppercase cursor-default"
            data-qa="header-title"
          >
            {text}
          </h1>
        </div>
      </div>
      {(!md && children && wrapChildren) && (
        <div className="mb-16">
          {children}
        </div>
      )}
    </div>
  );
};
export default Header;
