import React, { useReducer, createContext } from 'react';
import api from 'Api';
import configReducer from './configReducer';
import {
  LOAD_CONFIG_SUCCESS,
  SET_COOKIE_SETTINGS,
} from './configTypes';

const COOKIE_SETTINGS = 'wlp_user_cookie_settings';
const NON_NECESSARY_COOKIES_ACCEPTED = 'non_necessary_cookies_accepted';

export const ConfigContext = createContext();

const ConfigState = (props) => {
  const initialState = {
    config: null,
    cookieSettings: JSON.parse(localStorage.getItem(COOKIE_SETTINGS) || 'null'),
  };

  const [state, dispatch] = useReducer(configReducer, initialState);

  const loadConfig = async (isAuthenticated, tenantId = '') => {
    try {
      let config;
      if (isAuthenticated) {
        config = await api.Config.getFullConfig();
      } else {
        config = await api.Config.getReducedConfig(tenantId);
      }
      dispatch({
        type: LOAD_CONFIG_SUCCESS,
        payload: { config },
      });
      return config;
    } catch (err) {
      throw err;
    }
  };

  const setConfig = (config) => {
    try {
      dispatch({
        type: LOAD_CONFIG_SUCCESS,
        payload: { config },
      });
    } catch (err) {}
  };

  const setCookieSettings = (cookieSettings) => {
    localStorage.setItem(COOKIE_SETTINGS, JSON.stringify(cookieSettings));
    dispatch({
      type: SET_COOKIE_SETTINGS,
      payload: { cookieSettings },
    });
  };

  const enableNonNecessaryCookies = () => {
    setCookieSettings({ [NON_NECESSARY_COOKIES_ACCEPTED]: true });
  };

  const disableNonNecessaryCookies = () => {
    setCookieSettings({ [NON_NECESSARY_COOKIES_ACCEPTED]: false });
  };

  const nonNecessaryCookiesEnabled =
    state.cookieSettings?.[NON_NECESSARY_COOKIES_ACCEPTED];

  return (
    <ConfigContext.Provider
      value={{
        config: state.config,
        cookieSettings: state.cookieSettings,
        nonNecessaryCookiesEnabled,
        loadConfig,
        setConfig,
        setCookieSettings,
        enableNonNecessaryCookies,
        disableNonNecessaryCookies
      }}
    >
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigState;
