import React from 'react';

const MenuSymbol = ({ size = "16px" }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="currentColor"
  >
    <path d="M0 3.66666C0 3.32476 0.255837 3.04761 0.571429 3.04761H15.4286C15.7442 3.04761 16 3.32476 16 3.66666C16 4.00855 15.7442 4.2857 15.4286 4.2857H0.571429C0.255837 4.2857 0 4.00855 0 3.66666Z" />
    <path d="M0 7.99991C0 7.65802 0.255837 7.38086 0.571429 7.38086H15.4286C15.7442 7.38086 16 7.65802 16 7.99991C16 8.3418 15.7442 8.61895 15.4286 8.61895H0.571429C0.255837 8.61895 0 8.3418 0 7.99991Z" />
    <path d="M0 12.3334C0 11.9915 0.255837 11.7144 0.571429 11.7144H15.4286C15.7442 11.7144 16 11.9915 16 12.3334C16 12.6753 15.7442 12.9525 15.4286 12.9525H0.571429C0.255837 12.9525 0 12.6753 0 12.3334Z" />
  </svg>
);

export default MenuSymbol;
