import { useEffect, useRef, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import { STORAGE_KEYS } from 'Enums';
import { getUserManager } from 'Helpers/auth';
import { getPreferedRedirectPath } from 'Hooks/usePostLoginRedirect';
import useFeatureAvailability from 'Hooks/useFeatureAvailability';
import { EnvContext } from 'States/env/envState';
import { ROUTES } from 'Router/Routes';

const Login = () => {
  const location = useLocation();
  const currentPath = location.state?.[STORAGE_KEYS.authReturnPath];
  const returnPath = currentPath === ROUTES.error ? ROUTES.base : currentPath;
  const { setLoading: setLoadingContext } = useContext(AuthContext);
  const {
    config: { checkoutSettings }
  } = useContext(ConfigContext);
  const { env } = useContext(EnvContext);
  const { data: features } = useFeatureAvailability.query({
    queryFnArgs: env.TenantId,
    enabled: !!env && !returnPath,
    staleTime: 300000,
  });

  const setLoading = useRef(setLoadingContext);
  useEffect(() => {
    setLoading.current(true);
    async function login() {
      sessionStorage.setItem(
        STORAGE_KEYS.authReturnPath,
        returnPath || getPreferedRedirectPath(features, checkoutSettings.hideShop)
      );

      const userManager = await getUserManager();
      userManager.signinRedirect({
        state: {
          originUrl: checkoutSettings.hideShop ? ROUTES.base : ROUTES.shop
        }
      });
    }
    login();
  }, []);
  return null;
};

export default Login;
