import React, { useReducer, createContext, useState } from 'react';
import Big from 'big.js';
import api from 'Api';
import useHandleError from 'Utils/handleError';
import { objectArrayToObject } from 'Utils/utils';
import cartReducer from './cartReducer';
import {
  LOAD_CART,
  LOAD_CART_SUCCESS,
  PENDING_CART_OPERATION,
  PENDING_CART_OPERATION_SUCCESS,
  PENDING_QUANTITY_UPDATE,
  EMPTY_CART,
  REMOVE_FROM_CART_SUCCESS,
  SET_FULL_ASSET_INFO,
} from './cartTypes';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';

export const CartContext = createContext();

const CartState = (props) => {
  const initialState = {
    userId: props.userId,
    cartItems: {},
    fullAssetInfo: null,
    loadingCart: true,
    pendingCartOperation: false,
    pendingQuantityUpdate: false
  };

  const [state, dispatch] = useReducer(cartReducer, initialState);
  const [debounceTimeout, setDebounceTimeout] = useState(null);
  const handleError = useHandleError();

  const loadCart = async () => {
    try {
      dispatch({ type: LOAD_CART });
      const { value } = await api.Cart.get();
      dispatch({
        type: LOAD_CART_SUCCESS,
        payload: objectArrayToObject(value, 'UniqueAssetId'),
      });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartLoadCartError);
      handleError({ error, message });
    }
  };

  const addToCart = async (uniqueAssetId, sparkAmount) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      const itemInCart = state.cartItems[uniqueAssetId];
      if (itemInCart) {
        const newQuantity = Big(itemInCart.SparkAmount).plus(sparkAmount);
        const { Id, UniqueAssetId, UnitAmount, SparkAmount } = await api.Cart.updateItem(itemInCart.Id, { SparkAmount: newQuantity });
        dispatch({
          type: PENDING_CART_OPERATION_SUCCESS,
          payload: { Id, UniqueAssetId, UnitAmount, SparkAmount }
        });
      } else {
        const { Id, UniqueAssetId, UnitAmount, SparkAmount } = await api.Cart.addItem({
          UniqueAssetId: uniqueAssetId,
          SparkAmount: sparkAmount,
        });
        dispatch({
          type: PENDING_CART_OPERATION_SUCCESS,
          payload: { Id, UniqueAssetId, UnitAmount, SparkAmount }
        });
      }
    } catch (error) {
      dispatch({ type: PENDING_CART_OPERATION });
      const message = i18nextTranslate(i18nextKeys.errorStatesCartAddItemToCartError);
      handleError({ error, message });
    }
  };

  const removeFromCart = async (uniqueAssetId) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      const itemInCart = state.cartItems[uniqueAssetId];
      await api.Cart.removeItem(itemInCart.Id);
      dispatch({ type: REMOVE_FROM_CART_SUCCESS, payload: uniqueAssetId });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartRemoveItemToCartError);
      handleError({ error, message });
    }
  };

  const emptyCart = async () => {
    try {
      await api.Cart.empty();
      dispatch({ type: EMPTY_CART });
    } catch (error) {
      const message = i18nextTranslate(i18nextKeys.errorStatesCartEmptyCartError);
      handleError({ error, message });
    }
  };

  const setQuantity = (uniqueAssetId, sparkAmount) => {
    const maxPurchasableUnits = state.fullAssetInfo[uniqueAssetId].maxPurchasableUnits;
    const sparkFactor = state.fullAssetInfo[uniqueAssetId].SparkFactor;
    if (
      Big(sparkAmount).gt(Big(maxPurchasableUnits).times(sparkFactor)) ||
      sparkAmount === null ||
      sparkAmount === "0"
    ) {
      return;
    }
    setQuantityAtServer(uniqueAssetId, sparkAmount);
  };

  const setQuantityAtServer = async (uniqueAssetId, sparkAmount) => {
    try {
      dispatch({ type: PENDING_CART_OPERATION });
      const itemInCart = state.cartItems[uniqueAssetId];
      const { Id, UniqueAssetId, UnitAmount, SparkAmount } = await api.Cart.updateItem(
        itemInCart.Id, { SparkAmount: sparkAmount }
      );
      dispatch({
        type: PENDING_CART_OPERATION_SUCCESS,
        payload: { Id, UniqueAssetId, UnitAmount, SparkAmount }
      });
    } catch (error) {
      dispatch({ type: PENDING_CART_OPERATION });
      const message = i18nextTranslate(i18nextKeys.errorStatesCartSetQuantityError);
      handleError({ error, message });
    }
  };

  const setFullAssetInfo = (assetsFromBlob) => {
    dispatch({ type: SET_FULL_ASSET_INFO, payload: assetsFromBlob });
  };

  const setPendingQuantityUpdate = () => {
    dispatch({ type: PENDING_QUANTITY_UPDATE });
  };

  return (
    <CartContext.Provider
      value={{
        loadingCart: state.loadingCart,
        pendingCartOperation: state.pendingCartOperation,
        pendingQuantityUpdate: state.pendingQuantityUpdate,
        cartItems: state.cartItems,
        fullAssetInfo: state.fullAssetInfo,
        loadCart,
        addToCart,
        emptyCart,
        removeFromCart,
        setQuantity,
        setFullAssetInfo,
        setPendingQuantityUpdate
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartState;
