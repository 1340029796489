import { combineValidators } from 'revalidate';
import {
  isString,
  isRequiredBoolean,
  isRequiredString,
  isRequiredStringIf
} from 'Utils/validators';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import TENANT_FEATURE from 'Enums/TenantFeature';
import {
  checkoutConfigValidator,
  landingPageConfigValidator,
  redeemSettingsConfigValidator,
  assetsValidator,
  imprintConfigValidator,
  kycTiersDescriptionValidator,
  kycTierFunctionalRestrictionsValidator
} from './jsonConfigValidators';

export const validate = (config, features, settings) => {
  const refCodeTemplateRequired = isTenantFeatureAvailable(features, TENANT_FEATURE.purchase)
    || isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset);
  const isKycEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.kyc);
  const isFeaturedAssetEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.customFeaturedAsset);
  const isPurchaseEnabled = isTenantFeatureAvailable(features, TENANT_FEATURE.purchase);
  const defaultLanguageCode = settings.Languages.find(lang => lang.IsDefault)?.Code;
  return combineValidators({
    websiteTitle: isString('websiteTitle'),
    refCodeTemplate: isRequiredStringIf('refCodeTemplate', () => refCodeTemplateRequired),
    logo: isRequiredString('logo'),
    logoUrl: isString('logoUrl'),
    androidUrl: isString('androidUrl'),
    iosUrl: isString('iosUrl'),
    fullNameWelcome: isRequiredBoolean('fullNameWelcome'),
    landingPage: landingPageConfigValidator,
    imprint: imprintConfigValidator(defaultLanguageCode),
    kycTiersDescription: kycTiersDescriptionValidator('kycTiersDescription', isKycEnabled),
    kycTierFunctionalRestrictions: kycTierFunctionalRestrictionsValidator,
    checkoutSettings: checkoutConfigValidator(defaultLanguageCode),
    redeemSettings: redeemSettingsConfigValidator(defaultLanguageCode),
    assets: assetsValidator(
      'assets',
      isFeaturedAssetEnabled,
      isPurchaseEnabled,
      settings.RestrictedMode,
      defaultLanguageCode
    )
  })(config);
};

export const getValidationMessages = (validationResult) => {
  const validationMessages = [];
  getArbitrarilyNestedStringsFromObject(validationMessages, validationResult);
  return validationMessages;
};

const getArbitrarilyNestedStringsFromObject = (output, obj, parentKey = '') => {
  for (let key in obj) {
    if (typeof obj[key] == 'object') {
      getArbitrarilyNestedStringsFromObject(
        output,
        obj[key],
        parentKey + key + '.'
      );
    } else if (obj[key] !== undefined) {
      if (Array.isArray(obj)) {
        output.push(parentKey + key + ' ' + obj[key]);
      } else {
        output.push(parentKey + key + " " + obj[key]);
      }
    }
  }
};
