import React, { useContext } from "react";
import { Amplitude } from 'react-amplitude-hooks';
import { Link } from 'react-router-dom';
import { UiContext } from "States/ui/uiState";
import { CartIcon } from 'Components/shared/symbols';
import LoadingSpinner from 'Components/shared/LoadingSpinner';

const CartButton = ({
  itemsCount,
  isLoading = false
}) => {
  const {
    breakpoints: { md },
  } = useContext(UiContext);

  return (
    <Amplitude>
      {({ logEvent }) => (
        <Link
          to="/cart"
          className="flex"
          onClick={() => logEvent("Cart opened")}
        >
          <div className="relative z-40 bg-1 rounded-md flex justify-center items-center p-9 md:p-10">
            <CartIcon size={md ? 20 : 16} />
            <div
              className={`absolute flex justify-center items-center
                ${isLoading ? "p-3" : "px-6"}
              bg-4 rounded-full text-xs color-white`}
              style={{
                top: "-8px",
                right: "-8px"
              }}
            >
              {isLoading ? (
                <LoadingSpinner
                  size="h-12 w-12"
                  color="border-color-white"
                />
              ) : itemsCount}
            </div>

          </div>
        </Link>
      )}
    </Amplitude>
  );
};

export default CartButton;
