import React from "react";

const AndroidIcon = ({ size }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <path d="M3.5 8C4.327 8 5 8.673 5 9.5v7c0 .827-.673 1.5-1.5 1.5S2 17.327 2 16.5v-7C2 8.673 2.673 8 3.5 8zm17 0c.827 0 1.5.673 1.5 1.5v7c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5v-7c0-.827.673-1.5 1.5-1.5zm-3 0a.5.5 0 0 1 .5.5v8c0 1.208-.86 2.218-2 2.45v3.55c0 .827-.673 1.5-1.5 1.5s-1.5-.673-1.5-1.5V19h-2v3.5c0 .827-.673 1.5-1.5 1.5S8 23.327 8 22.5v-3.55a2.504 2.504 0 0 1-2-2.45v-8a.5.5 0 0 1 .5-.5h11zm-1.865-5.928C17.088 3.056 18 4.64 18 6.5a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5c0-1.86.912-3.444 2.365-4.428L7.147.854a.5.5 0 0 1 .707-.707l1.42 1.42A6.659 6.659 0 0 1 12 1c.995 0 1.916.205 2.726.567l1.42-1.42a.5.5 0 0 1 .707.707l-1.218 1.218zM9.5 5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm5 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
  </svg>
);

export default AndroidIcon;
