import React, { useContext } from "react";
import { useAmplitude } from 'react-amplitude-hooks';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom"
import { AuthContext } from 'States/auth/authState';
import { UiContext } from 'States/ui/uiState';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import { ROUTES } from "Router/Routes";
import { LogoutSymbol } from "../symbols";
import Button from "./Button";

const LoginLogout = ({ transparent = false }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const {
    breakpoints: { xl, md }
  } = useContext(UiContext);
  const location = useLocation();
  const { logEvent } = useAmplitude();
  const trackLogInOrOut = () => {
    const trackingOrigin = location.pathname.split("/")[1] || "home";
    if (isAuthenticated) {
      logEvent('Logout', { screen: trackingOrigin });
    } else {
      logEvent("Auth login started", { origin: trackingOrigin });
    }
  };

  return (
    <Link
      to={isAuthenticated ? ROUTES.logout : ROUTES.login}
      className={`ml-auto rounded cursor-pointer`}
      data-qa="welcome-login-logout"
    >
      {xl ? (
        <Button
          text = {i18nextTranslate(
            isAuthenticated
              ? i18nextKeys.buttonLogout
              : i18nextKeys.sidebarLoginRegister
          )}
          className="ml-auto h-40"
          data-qa="welcome-login-logout"
          onClick={trackLogInOrOut}
          secondary={transparent}
          tertiary={!transparent}
        />
      ) : (
        <div
          className={`flex items-center justify-center ${
              transparent ? "p-6 md:p-7 color-6" : "p-9 md:p-10 xl:p-0 color-7 bg-1"
          } rounded`}
        >
          <LogoutSymbol
            size={transparent ? "21px" : md ? "20px" : "16px"}
            onClick={trackLogInOrOut}
          />
        </div>
      )}
    </Link>
  );
};

export default LoginLogout;
