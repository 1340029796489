import React, { useEffect, useRef, useState } from "react";
import api from 'Api';
import { i18nextKeys } from "Lang/i18nextKeys";
import i18nextTranslate from "Lang/i18nextTranslate";
import LoadingSpinner from "Components/shared/LoadingSpinner";
import Text from "Components/shared/Text";
import { DYNAMIC_PRICE_POLLING_INTERVAL } from 'Helpers/assets';

const DigitalFineGoldPrices = () => {
  const [prices, setPrices] = useState([]);
  const [error, setError] = useState(null);
  const pollingInterval = useRef(null);

  const stopPolling = () => {
    if (pollingInterval.current) {
      clearInterval(pollingInterval.current);
      pollingInterval.current = null;
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        const prices = await api.CustomerFeatures.getFineGoldPrices();
        if (!prices.length) {
          setError(i18nextTranslate(i18nextKeys.errorDefaultMessage));
        }
        setPrices(prices);
      } catch (error) {
        setError(i18nextTranslate(i18nextKeys.errorDefaultMessage));
      }
    }
    if (!pollingInterval.current) {
      fetchPrices();
      pollingInterval.current = setInterval(fetchPrices, DYNAMIC_PRICE_POLLING_INTERVAL);
    }
    return stopPolling;
  }, []);

  return (
    <div className="py-12 px-16 m-16 bg-4_7 rounded">
      <div>
        <Text textStyle="h1" className="mb-6">
          {i18nextTranslate(i18nextKeys.customerDigitalFineGoldPriceGold)}
        </Text>
      </div>
      {!error && !prices.length && <LoadingSpinner size="h-32 w-32" />}
      {error && !prices.length && (
        <Text
          color="color-red"
          textStyle="p2"
        >
          {error}
        </Text>
      )}
      {prices.length ?
        prices.map((price, index) => (
          <div key={`${price.currency}-${index}`}>
            <Text>{`${price.currency}: ${price.buy}`}</Text>
          </div>
        )) : null
      }
    </div>
  );
};

export default DigitalFineGoldPrices;
