import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { Amplitude, LogOnMount } from 'react-amplitude-hooks';
import LoadingSpinner from 'Components/shared/LoadingSpinner';
import { AuthContext } from 'States/auth/authState';
import { CartContext } from 'States/cart/cartState';
import { ConfigContext } from 'States/config/configState';
import { LangContext } from 'States/lang/langState';
import AddToCart from './AddToCart';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import BurgerMenu from 'Components/shared/BurgerMenu';
import Welcome from 'Components/Welcome/Welcome';
import SignupReminder from 'Features/Signup/SignupReminder';
import useHandleError from 'Utils/handleError';
import Urls from 'Utils/urls';
import api from 'Api';
import { ROUTES } from 'Router/Routes';
import { getTranslation } from 'Helpers/translations';
import useConfigSettings from 'Hooks/useConfigSettings';
import useKycTierCheck from 'Hooks/useKycTierCheck';
import { UiContext } from 'States/ui/uiState';
import { LoginLogout } from 'Components/shared/buttons';
import CartButton from './CartButton';

const instance = axios.create();
instance.defaults.headers.common = {};

const FeaturedTokenPurchasePage = ({ featuredAsset, restrictedMode = false }) => {
  const history = useHistory();
  const handleError = useHandleError();
  const {
    isAdmin,
    isActive,
    isAuthenticated,
    isBlocked
  } = useContext(AuthContext);
  const [html, setHtml] = useState(null);
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState(null);
  const {
    config: {
      checkoutSettings,
      kycTierFunctionalRestrictions
    },
  } = useContext(ConfigContext);
  const { lang } = useContext(LangContext);
  const {
    breakpoints: { md, lg, xxl },
  } = useContext(UiContext);
  const {
    loadCart,
    cartItems,
    loadingCart,
  } = useContext(CartContext);

  const requiredTier = Math.max(
    kycTierFunctionalRestrictions?.availability?.purchase || 0,
    asset?.KycTier || 0
  );

  const { isUserTierSufficient } = useKycTierCheck(
    kycTierFunctionalRestrictions?.visibility?.purchase
  );

  const {
    data: defaultLanguage
  } = useConfigSettings.query({
    enabled: isAuthenticated,
    select: useCallback(
      ({ Languages }) => Languages.find(
        ({ IsDefault }) => IsDefault
      ), []
    )
  });

  useEffect(() => {
    if (!featuredAsset && (!isAuthenticated || isUserTierSufficient)) {
      history.replace(
        !isAdmin && (restrictedMode || checkoutSettings.hideShop)
          ? ROUTES.welcome
          : ROUTES.shop
      );
    }
  }, [featuredAsset, isUserTierSufficient]);

  useEffect(() => {
    const loadCartAndFeaturedAsset = async () => {
      try {
        loadCart();
        const featuredAssetData = await api.Assets.getAsset(featuredAsset.Id);
        setAsset({ ...featuredAsset, ...featuredAssetData });
      } catch (error) {
        const message = i18nextTranslate(i18nextKeys.errorPurchaseAssetLoadingError);
        handleError({ error, message });
      }
    };
    if (isActive) {
      loadCartAndFeaturedAsset();
    }
  }, [isActive, isAuthenticated]);

  // TODO: Ugly, needs rewriting
  const getCustomHtmlPath = (pathsArray) => {
    const currentLanguagePath = pathsArray.find(
      ({ LanguageCode, Value }) => !!Value && LanguageCode === lang
    );
    if (currentLanguagePath) {
      return currentLanguagePath.Value;
    }
    const defaultLanguagePath = pathsArray.find(
      ({ LanguageCode, Value }) => !!Value && LanguageCode === defaultLanguage?.Code
    );
    if (defaultLanguagePath) {
      return defaultLanguagePath.Value;
    }
    const englishLanguagePath = pathsArray.find(
      ({ LanguageCode, Value }) => !!Value && LanguageCode === "en"
    );
    if (englishLanguagePath) {
      return englishLanguagePath.Value;
    }
    const anyAvailablePath = pathsArray.find(
      ({ Value }) => !!Value
    );
    return anyAvailablePath?.Value || null;
  }

  useEffect(() => {
    const loadTemplate = async () => {
      try {
        let customHtmlPath;
        if (isAuthenticated && featuredAsset?.CustomPurchaseSettings?.CustomHtmlSourcePathLoggedIn) {
          customHtmlPath = getCustomHtmlPath(featuredAsset.CustomPurchaseSettings.CustomHtmlSourcePathLoggedIn) ||
          getCustomHtmlPath(featuredAsset.CustomPurchaseSettings.CustomHtmlSourcePath);
        } else {
          customHtmlPath = getCustomHtmlPath(featuredAsset.CustomPurchaseSettings.CustomHtmlSourcePath);
        }
        if (customHtmlPath) {
          const { data } = await instance.get(
            `${Urls.get()?.blob}/${customHtmlPath}`
          );
          if (data) {
            setHtml(data);
          }
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };

    loadTemplate();
  }, [isAuthenticated, lang]);

  const assetName = useMemo(() => {
    if (!asset) {
      return;
    }
    return getTranslation(
      "Name", lang, defaultLanguage.Code, asset.Translations
    ) || asset.Name;
  }, [asset, lang]);

  const showContent = !loading && (asset || !isAuthenticated || !isActive);

  return showContent ? (
    <Amplitude eventProperties={{ scope: 'featured' }}>
      <LogOnMount eventType="Featured opened">
        {html ? (
          <div className="revertPageContainer">
            {!lg ? (
              <div
                className="fixed z-40"
                style={{
                  top: '15px',
                  left: '15px',
                }}
              >
                <BurgerMenu md={md} />
              </div>
            ) : null}

            <div
              className='fixed flex gap-14 md:gap-20'
              style={{
                top: '15px',
                right: '15px'
              }}
            >
              {isActive && (
                <CartButton
                  itemsCount={Object.keys(cartItems).length}
                  isLoading={loadingCart}
                />
              )}
              <LoginLogout />
            </div>

            {isActive && (
              <div
                className="fixed z-40 bg-3 rounded-md px-16 pt-16 pb-12"
                style={{
                  width: xxl ? '514px' : md ? '405px' : '328px',
                  bottom: lg ? '24px' : '16px',
                  right: lg ? '24px' : '16px',
                  ...!md && { maxWidth: 'calc(100% - 32px)' }
                }}
              >
                <AddToCart
                  asset={asset ? { ...asset, Name: assetName } : {}}
                  defaultLanguage={defaultLanguage.Code}
                  requiredTier={requiredTier}
                  buttonWidth={{ xxl: '180px', default: '140px' }}
                  allowModals
                />
              </div>
            )}

            {isAuthenticated && !isActive && !isBlocked && (
              <SignupReminder
                text={i18nextTranslate(i18nextKeys.signupNotificationPurchase)}
              />
            )}
            <div
              style={{ minHeight: '100%' }}
              dangerouslySetInnerHTML={{ __html: html }}
            ></div>
          </div>
        ) : <Welcome />}
      </LogOnMount>
    </Amplitude>
  ) : (
    <div className="w-full h-full flex items-center justify-center">
      <LoadingSpinner />
    </div>
  );
};

export default FeaturedTokenPurchasePage;
