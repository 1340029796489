import React, { useContext, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { withRouter } from 'react-router-dom';
import { isTenantFeatureAvailable } from 'Helpers/toggleFeatures';
import LoadingPage from 'Components/LoadingPage/LoadingPage';
import Sidebar from 'Components/Sidebar/Sidebar';
import Overlay from 'Components/shared/Overlay';
import { UiContext } from 'States/ui/uiState';
import { AuthContext } from 'States/auth/authState';
import { ConfigContext } from 'States/config/configState';
import usePurchaseMethods from 'Hooks/usePurchaseMethods';
import { STORAGE_KEYS, TENANT_FEATURE } from 'Enums';
import { ROUTES } from './Routes';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import CookieNotice from 'Components/CookieNotice/CookieNotice';

const Router = ({
  features,
  restrictedMode
}) => {
  const [loading, setLoading] = useState(true);
  const [allowedRedemption, setAllowedRedemption] = useState(null);
  const [featuredAsset, setFeaturedAsset] = useState(null);
  const { isAuthenticated } = useContext(AuthContext);
  const { smallScreenSidebarShowing } = useContext(UiContext);
  const { cookieSettings } = useContext(ConfigContext);
  const location = useLocation();

  const isFeaturedAssetEnabled = isTenantFeatureAvailable(
    features,
    TENANT_FEATURE.customFeaturedAsset
  );

  const {
    data: featuredAssets
  } = usePurchaseMethods.featuredMethodsQuery({
    enabled: isFeaturedAssetEnabled
  });

  useEffect(() => {
    const setAllowedProducts = () => {
      if (isFeaturedAssetEnabled && !Array.isArray(featuredAssets)) {
        return;
      }
      setLoading(true);
      if (isFeaturedAssetEnabled && !restrictedMode && featuredAssets.length) {
        setFeaturedAsset(featuredAssets[0]);
        setLoading(false);
        return;
      }
      if (!restrictedMode) {
        setLoading(false);
        return;
      }
      const currentAssetId = matchPath(
        location.pathname,
        ROUTES.featuredAsset
      )?.params?.id;
      const currentRedemptionId = matchPath(
        location.pathname,
        ROUTES.redemptionForm
      )?.params?.id;

      const storedRedemptionId = currentAssetId
        ? sessionStorage.removeItem(STORAGE_KEYS.allowedRedemption)
        : sessionStorage.getItem(STORAGE_KEYS.allowedRedemption);
      const storedAssetId = currentRedemptionId
        ? sessionStorage.removeItem(STORAGE_KEYS.allowedAsset)
        : sessionStorage.getItem(STORAGE_KEYS.allowedAsset);

      const allowedRedemption = currentRedemptionId || storedRedemptionId;
      if (allowedRedemption) {
        sessionStorage.setItem(
          STORAGE_KEYS.allowedRedemption,
          allowedRedemption
        );
        setAllowedRedemption(allowedRedemption);
      }

      const allowedAsset = currentAssetId || storedAssetId;
      if (isFeaturedAssetEnabled && allowedAsset && featuredAssets?.length) {
        const featuredAssetDetails = featuredAssets.find(
          (asset) => asset.IsFeaturedAsset && allowedAsset === asset.Id
        );
        if (featuredAssetDetails) {
          setFeaturedAsset(featuredAssetDetails);
          sessionStorage.setItem(
            STORAGE_KEYS.allowedAsset,
            featuredAssetDetails.Id
          );
        }
      }
      setLoading(false);
    }
    if (restrictedMode || isFeaturedAssetEnabled) {
      setAllowedProducts();
    } else {
      setLoading(false);
    }
  }, [restrictedMode, featuredAssets, isFeaturedAssetEnabled]);

  return loading
    ? <LoadingPage />
    : (
      <div
        data-qa="main-content-container"
        className={`container flex flex-grow min-h-screen min-w-full font-sans ${
          smallScreenSidebarShowing ? 'overflow-hidden' : ''
        }`}
      >
        {/* overflow-auto" */}
        <Sidebar
          features={features}
          restrictedMode={restrictedMode}
          allowedRedemption={allowedRedemption}
          featuredAsset={featuredAsset}
        />
        <div
          className="flex flex-col flex-grow bg-2 w-full pageContainer min-h-full"
        >
          {isAuthenticated ? (
            <AuthenticatedApp
              features={features}
              restrictedMode={restrictedMode}
              allowedRedemption={allowedRedemption}
              featuredAsset={featuredAsset}
            />
          ) : (
            <UnauthenticatedApp
              features={features}
              featuredAsset={featuredAsset}
              restrictedMode={restrictedMode}
            />
          )}

          {/* No stored cookie settings found, therefore show cookie notice */}
          {cookieSettings == null ? (
            <CookieNotice margin={'sm:mr-24 md:ml-32 lg:mx-48 xl:mr-256'} />
          ) : null}
        </div>

        <ToastContainer
          position="bottom-right"
          transition={Slide}
          autoClose={5000}
          toastClassName="color-8"
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Overlay />
      </div>
    );
};

export default withRouter(Router);
