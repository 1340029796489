import React, { useContext, useEffect, useRef, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { Amplitude, LogOnMount, useAmplitude } from "react-amplitude-hooks";
import axios from 'axios';
import api from 'Api';
import { AuthContext } from "States/auth/authState";
import { ConfigContext } from "States/config/configState";
import { EnvContext } from "States/env/envState";
import CartState from "States/cart/cartState";
import KycState from "States/kyc/kycState";
import loadFavicon from "Helpers/loadFavicon";
import useHandleError from "Utils/handleError";
import Urls from "Utils/urls";
import Router from "Router";
import { ROUTES } from 'Router/Routes';
import LoadingPage from "Components/LoadingPage/LoadingPage";
import { getUserManager } from "Helpers/auth";
import { getAccountRedirectPath } from "Hooks/usePostLoginRedirect";
import { isTenantFeatureAvailable } from "Helpers/toggleFeatures";
import "Assets/tailwind.generated.css";
import "App.css";
import i18nextTranslate from "Lang/i18nextTranslate";
import { i18nextKeys } from "Lang/i18nextKeys";
import { LangContext } from "States/lang/langState";
import useFeatureAvailability from "Hooks/useFeatureAvailability";
import {
  REFERRAL_CODE_STATE,
  STORAGE_KEYS,
  TENANT_FEATURE,
  USER_STATUS
} from "Enums";
import useUserData from "Hooks/useUserData";
import useConfigSettings from "Hooks/useConfigSettings";
import usePostLoginRedirect from 'Hooks/usePostLoginRedirect';
import ColorMiddleware from "Middlewares/ColorMiddleware";

export default function App() {
  const [initializing, setInitializing] = useState(true);

  const location = useLocation();
  const history = useHistory();
  const handleError = useHandleError();
  const postAuthRedirect = usePostLoginRedirect();

  const {
    checkEmailConfirmation,
    setAuthenticated,
    isAuthenticated,
    user,
    status,
    getStatus,
    setAdmin,
    isAdmin
  } = useContext(AuthContext);
  const {
    loading: loadingEnv,
    loadEnv: loadEnvContext,
    env
  } = useContext(EnvContext);
  const loadEnv = useRef(loadEnvContext);
  const {
    config,
    loadConfig: loadConfigContext
  } = useContext(ConfigContext);
  const loadConfig = useRef(loadConfigContext);
  const { getLang } = useContext(LangContext);

  const {
    isLoading: isLoadingFeatures,
    data: features,
    refetch: fetchFeatures
  } = useFeatureAvailability.query({
    queryFnArgs: [env?.TenantId],
    enabled: false,
    onError: (error) => handleError({ error })
  });

  const {
    data: userData,
    refetch: fetchUserData
  } = useUserData.query({
    queryFnArgs: [
      user?.profile?.sub,
      isTenantFeatureAvailable(features, TENANT_FEATURE.kyc)
        ? "KycData"
        : ""
    ],
    enabled: false,
    onSuccess: ({ IsAdmin }) => setAdmin(IsAdmin),
    onError: (error) => handleError({ error })
  });

  const {
    isLoading: isLoadingConfigSettings,
    data: { RestrictedMode },
    refetch: fetchConfigSettings
  } = useConfigSettings.query({
    enabled: false,
    select: ({ RestrictedMode, Languages }) => {
      return ({
        RestrictedMode, Languages
      });
    }
  });

  // Load env settings before anything else so that URLs are discovered
  useEffect(() => {
    const loadEnvSettingsFromDiscoService = async () => {
      try {
        await loadEnv.current();
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorMessageLoadEnvSettings
        );
        handleError({ error, message });
      }
    };
    loadEnvSettingsFromDiscoService();
  }, [history]);

  const isAuthRedirect = !!matchPath(location.pathname, '/auth/callback');

  const handleNewUser = async (Language) => {
    try {
      const {
        IsActive,
        ReferralCodeState
      } = await api.User.getRegistrationTemplate();
      if (IsActive && ReferralCodeState !== REFERRAL_CODE_STATE.Disabled) {
        history.push(ROUTES.signup, {
            referralRequired: ReferralCodeState === REFERRAL_CODE_STATE.Required
        });
      } else {
        await api.User.signUp({ Language });
        const status = await getStatus();
        const isEmailConfirmed = await checkEmailConfirmation();
        const route = getAccountRedirectPath(status, isEmailConfirmed);
        if (route) {
          history.replace(route);
        }
      }
    } catch (error) {
      handleError({ error });
    }
  };

  const redirect = async (Languages, config) => {
    const status = await getStatus();
    if (status === USER_STATUS.New) {
      const language = getLang(null, Languages);
      await handleNewUser(language);
      setInitializing(false);
      return;
    }
    const { data } = await fetchUserData();
    getLang(data.Language, Languages);
    const isEmailConfirmed = await checkEmailConfirmation();
    postAuthRedirect({
      status,
      isEmailConfirmed,
      isAuthRedirect,
      hideShop: !data.IsAdmin && config.checkoutSettings.hideShop
    });
    setInitializing(false);
  };

  const authProcedure = async () => {
    try {
      const userManager = await getUserManager(env);
      userManager.clearStaleState();
      const user = isAuthRedirect
        ? await userManager.signinCallback()
        : await userManager.getUser();
      const { data: { Languages } } = await fetchConfigSettings();
      if (user) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`;
        setAuthenticated(true, user);
        const config = await loadConfig.current(true);
        redirect(Languages, config);
      } else {
        delete axios.defaults.headers.common['Authorization'];
        if (matchPath(location.pathname, [
          ROUTES.cart,
          ROUTES.checkout,
          ROUTES.redemption,
          ROUTES.account,
          ROUTES.admin.base
        ])) {
          sessionStorage.setItem(
            STORAGE_KEYS.authReturnPath,
            location.pathname
          );
          userManager.signinRedirect({
            state: { originUrl: location.pathname }
          });
        }
        setAuthenticated(false);
        await loadConfig.current(false, env.TenantId);
        getLang(null, Languages);
        setInitializing(false);
      }
    } catch (error) {
      setAuthenticated(false);
      const message = i18nextTranslate(
        i18nextKeys.errorMessageAuthProcessFail
      );
      handleError({ error, message });
    }
  };

  useEffect(() => {
    if (env && initializing) {
      try {
        Urls.initialize(env);
        fetchFeatures();
        authProcedure();
        const { BlobUrl } = env;
        loadFavicon(BlobUrl);
      } catch (error) {
        const message = i18nextTranslate(
          i18nextKeys.errorMessageLoadStylesheetFavicon
        );
        handleError({ error, message });
      }
    }
  }, [env]);

  // When config is loaded change the document title
  useEffect(() => {
    if (config) {
      document.title = config.websiteTitle;
    }
  }, [config]);

  const { logEvent } = useAmplitude();
  useEffect(() => {
    const isReload = window.performance
      .getEntriesByType('navigation')
      .some(({ type }) => type === 'reload')
    if (isReload) {
      logEvent("Refresh", {
        screen: location.pathname.split("/")[1] || "home"
      });
    }
  }, []);

  if (
    initializing ||
    loadingEnv ||
    isLoadingFeatures ||
    isLoadingConfigSettings
  ) {
    return <LoadingPage />;
  }

  return (
    <Amplitude
      userProperties={{
        appName: config.websiteTitle,
        loggedIn: isAuthenticated,
        role: isAdmin ? "admin" : "default",
        state: status
      }}
    >
      <LogOnMount
        eventType="Session started"
        eventProperties={{
          screen: location.pathname.split("/")[1] || "home",
          mode: RestrictedMode ? "direct_link" : "regular"
        }}
      >
        <ColorMiddleware>
          <KycState
            isKycEnabled={isTenantFeatureAvailable(features, TENANT_FEATURE.kyc)}
            currentKycTier={userData?.KycData?.UserTier}
            isAuthenticated={isAuthenticated}
          >
            <CartState userId={user?.profile?.sub}>
              <Router
                features={features}
                restrictedMode={!isAdmin && RestrictedMode}
              />
            </CartState>
          </KycState>
        </ColorMiddleware>
      </LogOnMount>
    </Amplitude>
  );
}
