import {
  LOAD_CART,
  LOAD_CART_SUCCESS,
  PENDING_CART_OPERATION,
  PENDING_CART_OPERATION_SUCCESS,
  PENDING_QUANTITY_UPDATE,
  EMPTY_CART,
  REMOVE_FROM_CART_SUCCESS,
  SET_FULL_ASSET_INFO
} from './cartTypes';

const cartReducer = (state, action) => {
  switch (action.type) {
    default:
      return state;

    case LOAD_CART: {
      return { ...state, loadingCart: true };
    }

    case LOAD_CART_SUCCESS: {
      return {
        ...state,
        loadingCart: false,
        cartItems: action.payload,
      };
    }

    case PENDING_CART_OPERATION: {
      return {
        ...state,
        pendingCartOperation: !state.pendingCartOperation
      };
    }

    case PENDING_CART_OPERATION_SUCCESS: {
      const cartItems = { ...state.cartItems };
      cartItems[action.payload.UniqueAssetId] = action.payload;
      return {
        ...state,
        cartItems,
        pendingCartOperation: false,
        pendingQuantityUpdate: false
      };
    }

    case PENDING_QUANTITY_UPDATE: {
      return {
        ...state,
        pendingQuantityUpdate: true
      }
    }

    case REMOVE_FROM_CART_SUCCESS: {
      const cartItems = { ...state.cartItems };
      delete cartItems[action.payload];
      return {
        ...state,
        pendingCartOperation: false,
        cartItems,
      };
    }

    case SET_FULL_ASSET_INFO: {
      return {
        ...state,
        fullAssetInfo: action.payload,
      };
    }

    case EMPTY_CART: {
      return {
        ...state,
        cartItems: {},
      };
    }
  }
};

export default cartReducer;
