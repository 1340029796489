import {
  combineValidators,
  composeValidators,
  createValidator
} from 'revalidate';
import {
  decimalPlacesValidator,
  isArray,
  isBoolean,
  isHexString,
  isNonNegativeInteger,
  isObject,
  isPositiveNumber,
  isRequiredIfWithMessage,
  isPositiveNumberOrNumericString,
  isRequiredString,
  isRequiredStringIf,
  isString,
  isValueUnique,
  hasLengthGreaterThanIf
} from 'Utils/validators';
import { i18nextKeys } from 'Lang/i18nextKeys';
import i18nextTranslate from 'Lang/i18nextTranslate';
import i18nextTranslateDynamically from 'Lang/i18nextTranslateDynamically';
import translationValidator from "./translationValidator";

const priceValidator = ({ field }, asset) => {
  const assetProps = Object.keys(asset);
  const isPriceSet = assetProps.includes("price");
  const isPriceSourceSet = assetProps.includes("priceSource");
  if (isPriceSet && isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedConflict, {
      firstProp: "price",
      secondProp: "priceSource"
    });
  }
  if (!isPriceSet && !isPriceSourceSet) {
    return i18nextTranslateDynamically(i18nextKeys.validatorRelatedMissing, {
      firstProp: "price",
      secondProp: "priceSource"
    });
  }
};

const featuredAssetValidator = (
  isFeaturedAssetEnabled,
  restrictedMode
) => createValidator(
  () => (assets) => {
    if (!isFeaturedAssetEnabled) {
      return;
    }
    let featuredCount = 0;
    for (const asset of assets) {
      if (typeof asset.isFeaturedAsset === "boolean"
        && asset.isFeaturedAsset) {
        featuredCount += 1;
        if (!restrictedMode && featuredCount > 1) {
          return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetSingleAsset);
        }
      }
    }
    if (!featuredCount) {
      return i18nextTranslate(i18nextKeys.validatorIsFeaturedAssetRequired);
    } 
    return;
  },
  () => {}
);

const customPurchaseSettingsValidator = (isFeaturedAssetEnabled, defaultLanguage) => {
  return (field, values) =>
    combineValidators({
      sidebarLink: isRequiredStringIf(
        'sidebarLink',
        () => isFeaturedAssetEnabled
      ),
      customHtmlSourcePath: composeValidators(
        isObject(""),
        translationValidator(defaultLanguage, isFeaturedAssetEnabled)
      )("customHtmlSourcePath"),
      customHtmlSourcePathLoggedIn: composeValidators(
        isObject(""),
        translationValidator(defaultLanguage)
      )("customHtmlSourcePathLoggedIn")
    })(values);
}

export const assetValidator = (isFeaturedAssetEnabled, defaultLanguage) => {
  return ({ field }, asset) => {

    return combineValidators({
      kycTier: isNonNegativeInteger('kycTier'),
      icon: isRequiredString('icon'),
      issuerAddress: isHexString('issuerAddress'),
      uniqueAssetId: isHexString('uniqueAssetId'),
      maxAmount: isPositiveNumber('maxAmount'),
      ...!asset.priceSource && {
        price: composeValidators(
          isPositiveNumberOrNumericString('price'),
          decimalPlacesValidator(2)
        )('price')
      },
      ...!asset.price && {
        priceSource: isString("priceSource")
      },
      isFeaturedAsset: isBoolean('isFeaturedAsset'),
      customPurchaseSettings: composeValidators(
        isRequiredIfWithMessage(
          (values) => { return values.isFeaturedAsset },
          'customPurchaseSettings'
        ),
        asset.isFeaturedAsset || asset.customPurchaseSettings
          ? customPurchaseSettingsValidator(
              isFeaturedAssetEnabled && asset.isFeaturedAsset,
              defaultLanguage
            )
          : () => {}
      )('customPurchaseSettings')
    })(asset);
  }
}

const assetArrayValidator = (isFeaturedAssetEnabled, defaultLanguage) =>
  (field, assets) =>
    assets.map(composeValidators(
      priceValidator,
      assetValidator(isFeaturedAssetEnabled, defaultLanguage)
    )('asset'));

const assetsValidator = (
  field,
  isFeaturedAssetEnabled,
  isPurchaseEnabled,
  restrictedMode,
  defaultLanguage
) =>
  composeValidators(
    isArray(field),
    hasLengthGreaterThanIf(
      0, isPurchaseEnabled, i18nextKeys.validatorAssetsRequired
    )('assets'),
    featuredAssetValidator(isFeaturedAssetEnabled, restrictedMode),
    isValueUnique(
      i18nextTranslate(i18nextKeys.validatorAssetsUniqueAssetIds),
      'uniqueAssetId'
    ),
    assetArrayValidator(isFeaturedAssetEnabled, defaultLanguage)
  )({ field });

export default assetsValidator;
