import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useAmplitude } from 'react-amplitude-hooks';
import { AuthContext } from 'States/auth/authState';
import { EnvContext } from 'States/env/envState';
import i18nextTranslate from 'Lang/i18nextTranslate';
import { i18nextKeys } from 'Lang/i18nextKeys';
import InfoModal from 'Components/shared/Modal/InfoModal';
import AddToCart from '../AddToCart';

const AssetModal = ({
  asset,
  defaultLanguage,
  show = () => {},
  close = () => {}
}) => {
  const history = useHistory();
  const { isAuthenticated } = useContext(AuthContext);
  const { env } = useContext(EnvContext);

  const { logEvent } = useAmplitude();

  const login = () => {
    logEvent("Auth login started", { origin: "purchase" });
    history.replace('/login');
  };

  const trackOpen = (isAvailable) => {
    logEvent("Add to cart modal opened", (inheritedProps) => ({
      ...inheritedProps,
      "items available": isAvailable
    }));
  };

  const content = {
    title: asset.Name,
    description: asset.Description,
    icon: `${env.BlobUrl}/${asset?.Icon}`
  }

  return (
    <InfoModal
      show={show}
      close={close}
      content={content}
      showButtons={!isAuthenticated}
      actionButton={{
        text: i18nextTranslate(i18nextKeys.buttonLoginToBuy),
        onClick: login,
      }}
      dataQa="addToCartModal"
    >
      {isAuthenticated && (
        <AddToCart
          asset={asset}
          defaultLanguage={defaultLanguage}
          closeModal={close}
          trackModal={trackOpen}
          buttonWidth={{
            xxl: '210px',
            md: '160px',
            default: '140px'
          }}
        />
      )}
    </InfoModal>
  );
};

export default AssetModal;
