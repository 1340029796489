import React, { useContext } from 'react';
import { UiContext } from 'States/ui/uiState';
import { MenuSymbol } from 'Components/shared/symbols';

const BurgerMenu = ({
  md = false,
  transparent = false
}) => {
  const {
    smallScreenSidebarShowing,
    setSmallScreenSidebarShowing,
  } = useContext(UiContext);

  return (
    <div
      onClick={() => setSmallScreenSidebarShowing(!smallScreenSidebarShowing)}
      className={`rounded cursor-pointer ${
        transparent ? "p-3 md:p-4 color-6" : "p-9 md:p-10 color-7 bg-1"
      }`}
    >
      <MenuSymbol size={transparent ? "27px" : md ? "20px" : "16px"} />
    </div>
  );
};

export default BurgerMenu;
